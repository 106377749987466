<template>
  <div>
    <v-card :loading="isLoading" class="mb-2">
      <v-card-title>
        <v-icon>mdi-waves-arrow-left</v-icon>
        Tedarikçi Borç - Alacak Toplamları
      </v-card-title>

      <v-card-text v-if="clusterId">
        <v-form @submit.prevent="handleFormSubmit('pdf')" ref="form">
          <v-row dense>
            <v-col sm="3">
              <rs-datepicker
                label="Başlangıç Tarihi"
                v-model="formData.date.min"
                :rules="[rules.maxDate(formData.date.min, formData.date.max)]"
                required
              />
            </v-col>

            <v-col sm="3">
              <rs-datepicker
                label="Bitiş Tarihi"
                v-model="formData.date.max"
                :rules="[rules.minDate(formData.date.max, formData.date.min)]"
                required
              />
            </v-col>

            <v-col md="3">
              <v-switch
                v-model="hideZeros"
                :disabled="disabled"
                label="Bakiyesi sıfır olanları gizle"
                hide-details="auto"
              ></v-switch>
            </v-col>

            <v-col sm="3">
              <v-btn
                :loading="isLoading"
                :disabled="isLoading"
                color="primary"
                type="submit"
                class="ms-3"
              >
                Göster
              </v-btn>

              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    class="ms-2"
                    color="primary"
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  >
                    İndir
                    <v-icon>mdi-menu-down-outline</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="handleDownloadClick('ods')">
                    <v-list-item-title>
                      <v-icon>mdi-table</v-icon> ODS
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('xlsx')">
                    <v-list-item-title>
                      <v-icon>mdi-table</v-icon> XLSX
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="handleDownloadClick('pdf')">
                    <v-list-item-title>
                      <v-icon>mdi-text-box-outline</v-icon> PDF
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-col>
          </v-row>
        </v-form>

        <iframe
          class="mt-3"
          :src="iframeUrl"
          frameborder="0"
          v-if="iframeUrl"
          style="width: 100%; height: 140vh"
        ></iframe>
      </v-card-text>

      <v-card-text v-else>
        Raporu görebilmek için üst menüden toplu yaşam alanı seçin.
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import { hasDataTable, hasForm } from "@/view/mixins";
import { slugify } from "@/core/helpers";

export default {
  name: "ExpenseDetailReport",
  mixins: [hasDataTable, hasForm],
  computed: {
    ...mapGetters(["cluster", "clusterId"]),
  },
  watch: {
    clusterId() {
      this.iframeUrl = null;
    },
  },
  data() {
    const date = new Date();
    const min = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), 1)
    );
    const max = new Date(
      Date.UTC(date.getUTCFullYear(), date.getUTCMonth() + 1, 0)
    );

    return {
      iframeUrl: null,
      reportData: null,
      hideZeros: false,
      formData: {
        date: {
          min: min.toJSON().split("T")[0],
          max: max.toJSON().split("T")[0],
        },
      },
    };
  },
  methods: {
    handleFormSubmit(format) {
      format = format || "pdf";
      if (this.isLoading || !this.$refs.form.validate() || !this.clusterId) {
        this.$toast.error("Lütfen tüm gerekli alanları doldurunuz");
        return false;
      }

      const params = Object.assign(this.buildSearchParams(this.formData));
      this.iframeUrl = null;
      this.isLoading = true;

      return this.$api
        .query(`reports/review-detail/${this.clusterId}`, { params })
        .then((response) => {
          this.reportData = Object.assign({}, response.data.data);
          for (const index in this.formData) {
            if (response.data.data[index] !== undefined) {
              this.reportData[index] = response.data.data[index];
            }
          }
          this.reportData.start_date = new Date(
            this.formData.date.min
          ).toLocaleDateString();
          this.reportData.end_date = new Date(
            this.formData.date.max
          ).toLocaleDateString();
          this.reportData.date = new Date().toLocaleString();
          delete this.reportData.past_periods;
          delete this.reportData.current_periods;

          if (this.hideZeros) {
            this.reportData.expenses = this.reportData.expenses.filter(
              (item) => {
                return (
                  item.total_remaining !== 0.0 && item.total_remaining !== 0
                );
              }
            );
          }

          for (const index in this.reportData.expenses) {
            const item = this.reportData.expenses[index];

            item.transfer_amount = this.numberToLocaleFormat(
              item.transfer_amount
            );
            item.amount = this.numberToLocaleFormat(item.amount);
            item.collecting = this.numberToLocaleFormat(item.collecting);
            item.remaining = this.numberToLocaleFormat(item.remaining);
            item.total_remaining = this.numberToLocaleFormat(
              item.total_remaining
            );
          }

          delete this.reportData.incomes;

          this.reportData.total_sums.expense_sums.transfer_amount =
            this.numberToLocaleFormat(
              this.reportData.total_sums.expense_sums.transfer_amount
            );
          this.reportData.total_sums.expense_sums.amount =
            this.numberToLocaleFormat(
              this.reportData.total_sums.expense_sums.amount
            );
          this.reportData.total_sums.expense_sums.collecting =
            this.numberToLocaleFormat(
              this.reportData.total_sums.expense_sums.collecting
            );
          this.reportData.total_sums.expense_sums.remaining =
            this.numberToLocaleFormat(
              this.reportData.total_sums.expense_sums.remaining
            );
          this.reportData.total_sums.expense_sums.total_remaining =
            this.numberToLocaleFormat(
              this.reportData.total_sums.expense_sums.total_remaining
            );

          delete this.reportData.total_sums.income_sums;

          return this.reportData;
        })
        .then((reportData) => {
          let fileName = this.cluster.name + "-";
          fileName += new Date(this.formData.date.min).toLocaleDateString();
          fileName += "-GiderDetayRaporu";
          fileName += "-";
          fileName += new Date(this.formData.date.max).toLocaleDateString();

          if (fileName.length >= 100) {
            fileName = fileName.substring(0, 99);
          }

          const params = {
            file_type: format,
            print_template: "ExpenseDetailReport",
            receiptData: reportData,
            file_name: slugify(fileName),
          };

          return this.$api.post("print-file", params).then((response) => {
            if (format !== "pdf") {
              const anchor = document.createElement("a");
              anchor.href = response.data.data.url;
              anchor.target = "_blank";
              anchor.click();
            } else {
              this.iframeUrl = response.data.data.url;
            }
          });
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleDownloadClick(format) {
      this.handleFormSubmit(format || "pdf", true);
    },
  },
};
</script>
